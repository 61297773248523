/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import claudinaryImage from '../utils/claudinaryImage';

export default function OtherPublications() {
  return (
    <div className="space-y-6">
      {/* Other Publications */}
      <h2 className="mt-5 text-lg font-bold text-gray-900 title-font">Otras Publicaciones</h2>

      <div>
        <a
          className="space-y-2"
          href="https://eloutput.com/redes-sociales/tiktok/cuentas-aprender-idiomas/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center overflow-hidden border border-gray-300 border-solid rounded-sm">
            <img
              src={claudinaryImage('/v1625677547/idiomas-tiktok-2-930x487.jpg')}
              className="object-cover object-center w-24 h-24 "
              alt="Aprender nuevos idiomas en TikTok es fácil con estas cuentas"
              style={{ minWidth: 94 }}
            />

            <p className="px-4 py-3 font-bold text-gray-900">
              <span>Aprender nuevos idiomas en TikTok es fácil con estas cuentas</span>
              <br />
              <small className="font-normal text-gray-600 font-proxima">Mayo 24, 2021</small>
            </p>
          </div>
        </a>
      </div>

      <div>
        <a
          className="space-y-2"
          href="https://es.digitaltrends.com/guias/las-cuentas-de-tiktok-que-te-muestran-como-hacer-de-todo-hazlo-por-ti-mismo/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center overflow-hidden border border-gray-300 border-solid rounded-sm">
            <img
              src={claudinaryImage('/v1603389896/poster-jw.png')}
              className="object-cover object-center w-24 h-24 "
              alt="Las cuentas de TikTok que te enseñan a hacer de todo"
              style={{ minWidth: 94 }}
            />

            <p className="px-4 py-3 font-bold text-gray-900">
              <span>Las cuentas de TikTok que te enseñan a hacer de todo</span>
              <br />
              <small className="font-normal text-gray-600 font-proxima">Mayo 17, 2021</small>
            </p>
          </div>
        </a>
      </div>

      <div>
        <a
          className="space-y-2"
          href="https://heraldodemexico.com.mx/tendencias/2020/7/23/cuentas-de-tiktok-para-aprender-idomas-hay-ingles-italiano-y-hasta-ruso-194960.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center overflow-hidden border border-gray-300 border-solid rounded-sm">
            <img
              src={claudinaryImage(
                '/v1625677797/210821219_1209256286179225_1550097635555864545_n.jpg'
              )}
              className="object-cover object-center w-24 h-24 "
              alt="Cuentas de TikTok para aprender idomas: Hay inglés, italiano ¡y hasta ruso!"
              style={{ minWidth: 94 }}
            />

            <p className="px-4 py-3 font-bold text-gray-900">
              <span>
                Cuentas de TikTok para aprender idomas: Hay inglés, italiano ¡y hasta ruso!
              </span>
              <br />
              <small className="font-normal text-gray-600 font-proxima">Julio 23, 2020</small>
            </p>
          </div>
        </a>
      </div>

      <div>
        <a
          className="space-y-2"
          href="https://lakalle.bluradio.com/noticias/virales/asi-si-aprendo-profesor-de-ingles-es-famoso-en-tiktok-por-su-parecido-con-ironman"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center overflow-hidden border border-gray-300 border-solid rounded-sm">
            <img
              src={claudinaryImage('/v1625677066/anddow.jpg')}
              className="object-cover object-center w-24 h-24 "
              alt="¡Así sí aprendo! Profesor de inglés es famoso en TikTok por su parecido con 'Ironman'"
              style={{ minWidth: 94 }}
            />

            <p className="px-4 py-3 font-bold text-gray-900">
              <span>
                ¡Así sí aprendo! Profesor de inglés es famoso en TikTok por su parecido con
                'Ironman'
              </span>
              <br />
              <small className="font-normal text-gray-600 font-proxima">Julio 7, 2020</small>
            </p>
          </div>
        </a>
      </div>

      {/* POLIVISION */}
      <div>
        <a
          className="space-y-2"
          href="https://polivision.modlangs.gatech.edu/10-rising-latinx-youtubers%E2%80%8B-expanding-the-conversation/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2 className="text-xl font-medium text-gray-900 title-font">
            <img
              className="h-8 -ml-3"
              src={claudinaryImage('/v1571782066/polivisionlogo.svg')}
              alt="polivision logo"
            />
          </h2>
          <div className="flex items-center overflow-hidden border border-gray-300 border-solid rounded-sm">
            <img
              src={claudinaryImage('/v1545515665/IMG_1328.jpg')}
              className="object-cover object-center w-24 h-24 "
              alt="10 Rising Latinx YouTubers​ Expanding the Conversation"
              style={{ minWidth: 94 }}
            />

            <p className="px-4 py-3 font-bold text-gray-900">
              <span>10 Rising Latinx YouTubers​ Expanding the Conversation</span>
              <br />
              <small className="font-normal text-gray-600 font-proxima">November 18, 2019</small>
            </p>
          </div>
        </a>
      </div>
    </div>
  );
}
