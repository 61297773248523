import React from 'react';
import claudinaryImage from '../utils/claudinaryImage';

export default function YoutubeNextCard({ className }) {
  return (
    <>
      <h2 className="mb-4 text-lg font-bold text-gray-900 title-font">
        Reconocimientos{' '}
        <span role="img" aria-label="medal emoji">
          🥇
        </span>
      </h2>
      <div
        className={`w-full ml-auto mr-auto overflow-hidden border border-gray-300 border-solid rounded-sm mb-6 ${className}`}
      >
        <img
          src={claudinaryImage('/v1572302673/ANDRES_PRIETO_FRANKLIN.jpg')}
          className="object-cover w-full h-64 object-middle"
          alt="Edward Enninful’s Top 10: The Best #VogueChallenge Reader Covers"
        />
        <p className="px-4 py-3 font-bold text-gray-900">
          <span>Youtube Next Up Winner </span>
          <br />
          <small className="font-normal text-gray-600 font-proxima">
            As a Winner of Youtube Next Up 2018 and Creator Advisor for Youtube Next Up 2019, Andrés
            is trying to empower the{' '}
            <a
              href="https://poli.vision/2019/08/27/10-rising-latinx-youtubers%e2%80%8b-expanding-the-conversation/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Latinx Community
            </a>{' '}
            in the United States and all around the world with his sense of humor and a contagious
            hunger for knowledge.
          </small>
        </p>
      </div>
    </>
  );
}
