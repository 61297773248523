import React, { useState } from 'react';
import * as EmailValidator from 'email-validator';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export default function NewsletterSubscribeForm({ className }) {
  const [email, setEmail] = useState('');
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const [subscribeError, setSubscribeError] = useState(false);
  const [subscribeErrorMessage, setSubscribeErrorMessage] = useState(
    'Yikes! there was a problem adding your email, please try again.'
  );

  const handleChange = event => setEmail(event.target.value);

  const handleSubmit = async event => {
    event.preventDefault();

    if (email !== '' && EmailValidator.validate(email)) {
      try {
        addToMailchimp(email).then(data => {
          if (data.result === 'success') {
            setEmail('');
            setSubscribeSuccess(true);
            setSubscribeError(false);
          }
          if (data.result === 'error') {
            if (data.msg.search('already subscribed') !== -1)
              setSubscribeErrorMessage('This email is already subscribed 🤷🏻‍♂️');
            else {
              setSubscribeErrorMessage(data.msg);
            }

            setEmail('');
            setSubscribeSuccess(false);
            setSubscribeError(true);
          }
        });
      } catch (err) {
        console.log(err); //eslint-disable-line
      }
    } else {
      alert('Please enter a valid email address');
    }
  };

  return (
    <div className={className}>
      {!subscribeSuccess && (
        <form onSubmit={handleSubmit}>
          <input
            className="w-full px-4 py-2 mr-2 text-base bg-gray-100 border border-gray-400 rounded sm:w-64 sm:mr-4 focus:outline-none focus:border-indigo-500"
            onChange={handleChange}
            value={email}
            placeholder="Email Address"
            type="email"
          />
          <button className="w-full mt-4 sm:mt-0 btn-red sm:w-40" type="submit">
            Subscribe
          </button>
        </form>
      )}
      {subscribeSuccess && (
        <p className="mt-4 text-green-600">
          Thank you for subscribing!{' '}
          <span role="img" aria-label="success emoji">
            🎉
          </span>
        </p>
      )}
      {subscribeError && <p className="mt-4 text-red-600">{subscribeErrorMessage}</p>}
    </div>
  );
}
