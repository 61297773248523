import React from 'react';
import { FaYoutube, FaTwitter, FaLinkedinIn, FaInstagram, FaFacebookF } from 'react-icons/fa';
import claudinaryImage from '../utils/claudinaryImage';

export default function SocialIconsBar() {
  return (
    <div>
      <FooterLinkIcon url="https://www.youtube.com/user/soyimaginario" component={FaYoutube} />
      <FooterLinkIcon url="https://www.instagram.com/andreseningles/" component={FaInstagram} />
      <FooterLinkIcon url="https://www.facebook.com/andreseningles" component={FaFacebookF} />
      <FooterLinkIcon url="https://twitter.com/andreseningles_" component={FaTwitter} />
      <FooterLinkIcon url="https://www.linkedin.com/in/andrespfranklin/" component={FaLinkedinIn} />
      <a href="https://www.tiktok.com/@andreseningles" target="_blank" rel="noopener noreferrer">
        <img
          src={claudinaryImage('/v1595374855/tiktok-white-logo-svg.svg')}
          alt="tiktok logo"
          style={{ width: 35 }}
        />
      </a>
    </div>
  );
}

export const FooterLinkIcon = ({ className, component, url, size = '1.8em' }) => {
  const Component = component;
  return (
    <a className={className} href={url} target="_blank" rel="noopener noreferrer">
      <Component size={size} />
    </a>
  );
};
