import React from 'react';

export default function UnivisionLogoSVG({ className }) {
  return (
    <svg width="152" height="30" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M73.8 21.5h-3.68V7.78h3.68V21.5zm23.6 0h-3.68V7.78h3.68V21.5zm20.57 0h-3.68V7.78h3.68V21.5zm-60.9 0h-3.69v-7.45c0-3.5 2.26-6.56 6.78-6.56 4.2 0 6.63 3.08 6.63 6.56v7.45H63.1v-7.59c0-1.7-1.33-3-3.03-3a2.92 2.92 0 0 0-3.01 3v7.6zm84.45 0h-3.68v-7.45c0-3.5 2.26-6.56 6.78-6.56 4.2 0 6.62 3.08 6.62 6.56v7.45h-3.67v-7.59c0-1.7-1.34-3-3.03-3a2.92 2.92 0 0 0-3.02 3v7.6zm-57.76-5.34l3.76-8.38h3.82l-6.3 13.72h-2.55L76.2 7.78h3.8l3.77 8.38zm18.38 1.16a5.79 5.79 0 0 0 3.76 1.35c.99 0 1.75-.48 1.75-1.15 0-2.07-6.64-1.33-6.64-5.75 0-2.64 2.54-4.28 5.26-4.28 1.78 0 3.73.68 4.64 1.39l-1.72 2.66a4.65 4.65 0 0 0-2.64-.93c-1.01 0-1.87.4-1.87 1.1 0 1.79 6.65 1.07 6.65 5.84 0 2.63-2.57 4.24-5.46 4.24-1.89 0-3.9-.65-5.5-1.84l1.77-2.63zm25.77 1.07c2.15 0 3.62-1.67 3.62-3.73 0-2.07-1.47-3.77-3.62-3.77-2.14 0-3.62 1.7-3.62 3.77 0 2.06 1.48 3.73 3.62 3.73zm0-10.9c4.16 0 7.29 3.2 7.29 7.17a7.11 7.11 0 0 1-7.29 7.13 7.15 7.15 0 0 1 0-14.3zm-81.22.29h3.68v7.45c0 3.5-2.27 6.56-6.79 6.56a6.35 6.35 0 0 1-6.62-6.56V7.78h3.67v7.6a3 3 0 0 0 3.03 3c1.6 0 3.03-1.13 3.03-3v-7.6z"
          fill="#6D6D6D"
        />
        <path
          d="M12.02 0V16.58c.82 0 5.43.12 9.33-.23.98-.1 1.94-.22 2.86-.38 2.24-.43 4.13-1.1 4.79-1.65V1.65C27.29.38 20.1 0 12.02 0"
          fill="#00C56E"
        />
        <path
          d="M29 14.54v-.22c-.66.55-2.55 1.22-4.8 1.65-.9.15-1.87.28-2.85.38-3.9.35-8.5.23-9.33.23v.29l.02 1.86.02.53c.02.8.06 1.6.11 2.38.1 1.64.29 3.18.57 4.46.32 1.43.77 2.55 1.4 3.14l.03.03.62.02c4.82 0 9.07-2.4 11.64-6.07a14.61 14.61 0 0 0 2.07-4.87c.33-1.2.5-2.48.5-3.8v-.01"
          fill="#1717EF"
        />
        <path
          d="M14.14 29.24c-.63-.59-1.08-1.7-1.4-3.14a30.71 30.71 0 0 1-.57-4.46 66.94 66.94 0 0 1-.15-4.78v-.28c-3.74 0-6.99-.28-8.54-.56-1.63-.3-2.71-.9-2.9-1.2a14.12 14.12 0 0 0 2.59 8.42 14.19 14.19 0 0 0 11 6.03l-.03-.03"
          fill="#FF161F"
        />
        <path
          d="M1.13.98c-.3 0-.55.25-.55.55v13.29c.19.3 1.27.9 2.9 1.2l.89.13c1.73.23 4.51.43 7.65.43V6.36C8.48 1.8 3 .98 1.13.98"
          fill="#E621BB"
        />
      </g>
    </svg>
  );
}
