import React from 'react';
import HomepageVideo from './HomepageVideo';

export default function LatestVideo({ youtube, className }) {
  return (
    <div className={className}>
      <h2 className="mb-4 text-lg font-bold text-gray-900 title-font">
        Mi video mas reciente{' '}
        <span role="img" aria-label="video emoji">
          🎬
        </span>
      </h2>
      <div className="overflow-hidden rounded-lg">
        <HomepageVideo
          id={youtube.latestVideo.videoId}
          title={youtube.latestVideo.title}
          src={youtube.latestVideo.thumbnail.url}
        />
      </div>
      <a
        href="https://www.youtube.com/user/soyimaginario"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center mt-3 text-radical-red-500"
      >
        Visit my channel
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-4 h-4 ml-2"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h14M12 5l7 7-7 7" />
        </svg>
      </a>
    </div>
  );
}
