import React from 'react';

export default function AdrianLogo({ className }) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <path
          d="M85.04,21.33c2.1,0,4.2,0,6.36,0c0,4.25,0,8.46,0,12.72c2.14,0,4.21,0,6.33,0c0,10.63,0,21.21,0,31.87
		c-2.1,0-4.21,0-6.33,0l0.01,0.01c-0.01-0.29-0.03-0.57-0.03-0.86c0-7.94,0-15.88,0-23.81c0-0.25,0-0.51,0-0.75
		c-2.17,0-4.23,0-6.35,0c0,4.25,0,8.44,0,12.71c-2.14,0-4.23,0-6.37,0c0,2.15,0,4.22,0,6.3c-6.41,0-12.76,0-19.13,0
		c0-2.15,0-4.25,0-6.35c2.11,0,4.23,0,6.41,0c0-2.16,0-4.25,0-6.38c2.14,0,4.21,0,6.3,0c0-2.13,0-4.22,0-6.31
		c-4.25,0-8.46,0-12.69,0c0,4.27,0,8.49,0,12.71c-2.11,0-4.23,0-6.42,0c0-2.16,0-4.24,0-6.31c-2.15,0-4.21,0-6.33,0
		c0,2.12,0,4.19,0,6.34c-2.14,0-4.23,0-6.37,0c0,2.15,0,4.22,0,6.3c-6.41,0-12.76,0-19.13,0c0-2.15,0-4.25,0-6.35
		c2.11,0,4.23,0,6.41,0c0-2.16,0-4.25,0-6.38c2.14,0,4.21,0,6.3,0c0-2.13,0-4.22,0-6.31c-4.25,0-8.46,0-12.69,0
		c0,4.27,0,8.49,0,12.71c-2.11,0-4.23,0-6.42,0c0-4.28,0-8.48,0-12.69c-2.14,0-4.2,0-6.31,0c0,0.31,0,0.57,0,0.83
		c0,7.91,0,15.82,0,23.73c0,0.29-0.02,0.57-0.03,0.86l0.01-0.01c-2.1,0-4.2,0-6.33,0c0-10.62,0-21.2,0-31.83c2.08,0,4.15,0,6.3,0
		c0-4.26,0-8.47,0-12.76c2.18,0,4.3,0,6.41,0c0,0-0.01-0.01-0.01-0.01c0.01,0.26,0.03,0.53,0.03,0.79c0,3.72,0,7.43,0,11.15
		c0,0.25,0,0.51,0,0.75c23.4,0,46.69,0,70.04,0c0-0.96,0-1.88,0-2.81c0.01-3.3,0.02-6.59,0.03-9.89L85.04,21.33z"
        />
        <path
          d="M34.08,8.59c0-2.1,0-4.19,0-6.33c10.61,0,21.19,0,31.85,0c0,2.11,0,4.22,0,6.33c0,0,0.01-0.01,0.01-0.01
		c-0.18,0.01-0.35,0.03-0.53,0.03c-10.27,0-20.55,0-30.82,0c-0.18,0-0.35-0.02-0.53-0.03L34.08,8.59z"
        />
        <path
          d="M65.93,91.41c0,2.1,0,4.19,0,6.33c-10.61,0-21.19,0-31.85,0c0-2.11,0-4.22,0-6.33l-0.01,0.01
		c0.18-0.01,0.35-0.03,0.53-0.03c10.27,0,20.55,0,30.82,0c0.18,0,0.35,0.02,0.53,0.03L65.93,91.41z"
        />
        <path
          d="M21.34,14.96c0-2.1,0-4.19,0-6.37c4.27,0,8.51,0,12.74,0c0,0-0.01-0.01-0.01-0.01c0,2.11,0,4.22,0,6.4
		c-0.99,0-1.93,0-2.87,0c-3.29-0.01-6.58-0.02-9.87-0.03C21.32,14.95,21.34,14.96,21.34,14.96z"
        />
        <path
          d="M65.93,8.59c4.22,0,8.44,0,12.74,0c0,2.15,0,4.26,0,6.37c0,0,0.01-0.01,0.01-0.01c-0.09,0.01-0.17,0.03-0.26,0.03
		c-4.02,0-8.04,0-12.06,0c-0.14,0-0.29-0.04-0.42-0.07c0-2.14,0-4.24,0-6.34L65.93,8.59z"
        />
        <path
          d="M14.96,78.67c-2.1,0-4.19,0-6.37,0c0-4.27,0-8.51,0-12.74c0,0-0.01,0.01-0.01,0.01c2.11,0,4.22,0,6.4,0
		c0,0.99,0,1.93,0,2.87c-0.01,3.29-0.02,6.58-0.03,9.87C14.95,78.68,14.96,78.67,14.96,78.67z"
        />
        <path
          d="M91.42,65.92c0,4.22,0,8.44,0,12.74c-2.15,0-4.26,0-6.37,0l0.01,0.01c-0.01-0.09-0.03-0.17-0.03-0.26
		c0-4.02,0-8.04,0-12.06c0-0.14,0.04-0.29,0.07-0.42c2.14,0,4.24,0,6.34,0L91.42,65.92z"
        />
        <path
          d="M34.08,91.41c-4.22,0-8.44,0-12.74,0c0-2.15,0-4.26,0-6.37l-0.01,0.01c0.09-0.01,0.17-0.03,0.26-0.03
		c4.02,0,8.04,0,12.06,0c0.14,0,0.29,0.04,0.42,0.07c0,2.14,0,4.24,0,6.34L34.08,91.41z"
        />
        <path
          d="M78.67,85.04c0,2.1,0,4.19,0,6.37c-4.27,0-8.51,0-12.74,0l0.01,0.01c0-1.58,0-3.16,0-4.82c2.13,0,4.21,0,6.35,0
		c0-0.55,0-1.03,0-1.55c2.17,0,4.28,0,6.39,0L78.67,85.04z"
        />
        <path
          d="M14.96,21.33c0-2.1,0-4.19,0-6.37c2.15,0,4.26,0,6.37,0c0,0-0.01-0.01-0.01-0.01c0,2.11,0,4.22,0,6.37
		c-2.15,0-4.26,0-6.37,0L14.96,21.33z"
        />
        <path
          d="M78.67,14.96c2.1,0,4.19,0,6.37,0c0,2.15,0,4.26,0,6.37l0.01-0.01c-2.11,0-4.22,0-6.37,0c0-2.15,0-4.26,0-6.37L78.67,14.96
		z"
        />
        <path
          d="M21.34,85.04c-2.1,0-4.19,0-6.37,0c0-2.15,0-4.26,0-6.37c0,0-0.01,0.01-0.01,0.01c2.11,0,4.22,0,6.37,0
		c0,2.15,0,4.26,0,6.37L21.34,85.04z"
        />
        <path
          d="M85.04,78.67c0,2.1,0,4.19,0,6.37c-2.15,0-4.26,0-6.37,0l0.01,0.01c0-2.11,0-4.22,0-6.37c2.15,0,4.26,0,6.37,0L85.04,78.67
		z"
        />
        <path
          fill="#FFEB3B"
          d="M85.06,78.68c-2.11,0-4.22,0-6.37,0c0,2.15,0,4.26,0,6.37c-2.11,0-4.22,0-6.39,0c0,0.52,0,0.99,0,1.55
		c-2.14,0-4.22,0-6.35,0c0,1.66,0,3.24,0,4.82c-0.18-0.01-0.35-0.03-0.53-0.03c-10.27,0-20.55,0-30.82,0
		c-0.18,0-0.35,0.02-0.53,0.03c0-2.1,0-4.19,0-6.34c-0.13-0.02-0.28-0.07-0.42-0.07c-4.02,0-8.04,0-12.06,0
		c-0.09,0-0.17,0.02-0.26,0.03c0-2.11,0-4.22,0-6.37c-2.15,0-4.26,0-6.37,0c0.01-3.29,0.02-6.58,0.03-9.87c0-0.94,0-1.89,0-2.87
		c-2.18,0-4.29,0-6.4,0c0.01-0.29,0.03-0.57,0.03-0.86c0-7.91,0-15.82,0-23.73c0-0.26,0-0.52,0-0.83c2.11,0,4.17,0,6.31,0
		c0,4.21,0,8.41,0,12.69c2.19,0,4.3,0,6.42,0l-0.02-0.02c0,2.1,0,4.2,0,6.35c6.37,0,12.72,0,19.13,0c0-2.08,0-4.15,0-6.3
		c2.14,0,4.23,0,6.37,0c0-2.15,0-4.22,0-6.34c2.12,0,4.19,0,6.33,0c0,2.07,0,4.15,0,6.31c2.19,0,4.31,0,6.42,0l-0.02-0.02
		c0,2.1,0,4.2,0,6.35c6.38,0,12.73,0,19.13,0c0-2.08,0-4.15,0-6.3c2.14,0,4.23,0,6.37,0c0-4.27,0-8.46,0-12.71c2.12,0,4.18,0,6.35,0
		c0,0.24,0,0.49,0,0.75c0,7.94,0,15.88,0,23.81c0,0.29,0.02,0.57,0.03,0.86c-2.1,0-4.19,0-6.34,0c-0.02,0.13-0.07,0.28-0.07,0.42
		c0,4.02,0,8.04,0,12.06C85.03,78.51,85.05,78.59,85.06,78.68z M69.13,72.31c-0.13-0.01-0.26-0.03-0.4-0.03c-8.21,0-16.41,0-24.62,0
		c-0.15,0-0.3,0.02-0.47,0.04c0,2.13,0,4.22,0,6.33c8.51,0,16.99,0,25.47,0c0-2.15,0-4.25,0-6.35c2.11,0,4.23,0,6.37,0
		c0-2.14,0-4.23,0-6.33c-2.14,0-4.24,0-6.35,0C69.13,68.11,69.13,70.21,69.13,72.31z"
        />
        <path
          fill="#FFEB3B"
          d="M78.69,14.95c0,2.11,0,4.22,0,6.37c2.15,0,4.26,0,6.37,0c-0.01,3.3-0.02,6.59-0.03,9.89c0,0.92,0,1.85,0,2.81
		c-23.35,0-46.64,0-70.04,0c0-0.24,0-0.49,0-0.75c0-3.72,0-7.43,0-11.15c0-0.26-0.02-0.53-0.03-0.79c2.11,0,4.22,0,6.37,0
		c0-2.15,0-4.26,0-6.37c3.29,0.01,6.58,0.02,9.87,0.03c0.94,0,1.89,0,2.87,0c0-2.18,0-4.29,0-6.4c0.18,0.01,0.35,0.03,0.53,0.03
		c10.27,0,20.55,0,30.82,0c0.18,0,0.35-0.02,0.53-0.03c0,2.1,0,4.19,0,6.34c0.13,0.02,0.28,0.07,0.42,0.07c4.02,0,8.04,0,12.06,0
		C78.51,14.98,78.6,14.96,78.69,14.95z"
        />
        <path
          fill="#FFFFFF"
          d="M21.34,53.2c0-4.22,0-8.44,0-12.71c4.23,0,8.44,0,12.69,0c0,2.09,0,4.17,0,6.31c-2.09,0-4.16,0-6.3,0
		c0,2.13,0,4.22,0,6.38c-2.19,0-4.3,0-6.41,0C21.32,53.18,21.34,53.2,21.34,53.2z"
        />
        <path
          fill="#FFFFFF"
          d="M59.57,53.2c0-4.22,0-8.44,0-12.71c4.23,0,8.44,0,12.69,0c0,2.09,0,4.17,0,6.31c-2.09,0-4.16,0-6.3,0
		c0,2.13,0,4.22,0,6.38c-2.19,0-4.3,0-6.41,0C59.55,53.18,59.57,53.2,59.57,53.2z"
        />
        <path
          d="M69.11,72.29c0,2.1,0,4.2,0,6.35c-8.48,0-16.96,0-25.47,0c0-2.11,0-4.2,0-6.33c0.17-0.01,0.32-0.04,0.47-0.04
		c8.21,0,16.41,0,24.62,0c0.13,0,0.26,0.02,0.4,0.03L69.11,72.29z"
        />
        <path
          d="M69.13,72.31c0-2.1,0-4.2,0-6.35c2.11,0,4.21,0,6.35,0c0,2.11,0,4.19,0,6.33c-2.14,0-4.25,0-6.37,0
		C69.11,72.29,69.13,72.31,69.13,72.31z"
        />
      </g>
    </svg>
  );
}
