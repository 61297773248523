import React from 'react';

export default function ResponsiveIframe({ src, title, className }) {
  return (
    <div
      className={`relative h-0 overflow-hidden ${className}`}
      style={{ paddingBottom: '56.25%' }}
    >
      <iframe
        allowFullScreen
        frameBorder="0"
        height="100%"
        src={src}
        title={title || ''}
        width="100%"
        className="absolute top-0 left-0"
      />
    </div>
  );
}
