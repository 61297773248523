import React from 'react';
import claudinaryImage from '../utils/claudinaryImage';

export default function ImageLink({ href, alt, src }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" title={alt}>
      <img
        src={claudinaryImage(src)}
        className="block object-cover w-full p-2 mb-6 border rounded shadow object-middle "
        alt={alt}
      />
    </a>
  );
}
