import React from 'react';
import ResponsiveIframe from './ResponsiveIframe';

export default function FacebookVideo({ className }) {
  return (
    <ResponsiveIframe
      className={className}
      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdespiertamerica%2Fvideos%2F612220232647699%2F&show_text=0&width=560"
      title="Andres Prieto Franklin"
    />
  );
}
