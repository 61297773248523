import React from 'react';

export default function TikTokTextSVG() {
  return (
    <svg height="18px" viewBox="0 0 97 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="页面1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="编组" transform="translate(0.770000, 0.280000)" fillRule="nonzero">
          <polygon
            id="路径"
            fill="#000000"
            points="3.55271368e-15 0.06 16.12 0.06 14.64 4.72 10.46 4.72 10.46 21.72 5.23 21.72 5.23 4.72 0.01 4.72"
          />
          <polygon
            id="路径"
            fill="#000000"
            points="42.52 0.06 59.01 0.06 57.53 4.72 52.99 4.72 52.99 21.72 47.77 21.72 47.77 4.72 42.53 4.72"
          />
          <polygon id="路径" fill="#000000" points="17.1 6.95 22.27 6.95 22.27 21.72 17.14 21.72" />
          <polygon
            id="路径"
            fill="#000000"
            points="24.32 0 29.48 0 29.48 10.09 34.6 5.09 40.76 5.09 34.29 11.37 41.54 21.72 35.85 21.72 31.01 14.53 29.48 16.01 29.48 21.72 24.32 21.72"
          />
          <polygon
            id="路径"
            fill="#000000"
            points="79.01 0 84.23 0 84.23 10.09 89.34 5.09 95.5 5.09 89.03 11.37 96.23 21.72 90.54 21.72 85.71 14.53 84.23 16.01 84.23 21.72 79.06 21.72"
          />
          <circle id="椭圆形" fill="#000000" cx="19.69" cy="2.66" r="2.6" />
          <path
            d="M58.35,12.88 C58.3515814,8.26657269 61.9006475,4.43009758 66.5,4.07 C66.27,4.07 65.96,4.07 65.73,4.07 C61.0556946,4.34264957 57.4047572,8.21274958 57.4047572,12.895 C57.4047572,17.5772504 61.0556946,21.4473504 65.73,21.72 C65.96,21.72 66.27,21.72 66.5,21.72 C61.8891307,21.3590221 58.3358914,17.5049564 58.35,12.88 Z"
            id="路径"
            fill="#25F4EE"
          />
          <path
            d="M68.51,4.04 C68.27,4.04 67.96,4.04 67.73,4.04 C72.31446,4.41865637 75.8423325,8.24992889 75.8423325,12.85 C75.8423325,17.4500711 72.31446,21.2813436 67.73,21.66 C67.96,21.66 68.27,21.66 68.51,21.66 C73.3921972,21.66 77.35,17.7021972 77.35,12.82 C77.35,7.93780281 73.3921972,3.98 68.51,3.98 L68.51,4.04 Z"
            id="路径"
            fill="#FE2C55"
          />
          <path
            d="M67.11,17.18 C64.7351756,17.18 62.81,15.2548244 62.81,12.88 C62.81,10.5051756 64.7351756,8.58 67.11,8.58 C69.4848244,8.58 71.41,10.5051756 71.41,12.88 C71.4045016,15.2525432 69.4825432,17.1745016 67.11,17.18 L67.11,17.18 Z M67.11,4.04 C62.2278028,4.04 58.27,7.99780281 58.27,12.88 C58.27,17.7621972 62.2278028,21.72 67.11,21.72 C71.9921972,21.72 75.95,17.7621972 75.95,12.88 C75.95,10.5354862 75.0186455,8.28699764 73.3608239,6.62917605 C71.7030024,4.97135447 69.4545138,4.04 67.11,4.04 Z"
            id="形状"
            fill="#000000"
          />
        </g>
      </g>
    </svg>
  );
}
