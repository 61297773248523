import React from 'react';

export default function ButtonLink({ href = '', text = '', variant = 'red' }) {
  return (
    <a
      href={href}
      className={`block w-full py-3 font-bold text-sm text-center capitalize ${
        variant === 'red' ? 'btn-red' : 'btn-green'
      }`}
      target="_blank"
      rel="noopener noreferrer"
      title={text}
    >
      {text}
    </a>
  );
}
