/* eslint-disable no-irregular-whitespace */
import React from 'react';

export default function HomepageHeroText({ youtubeFollowers }) {
  console.log(youtubeFollowers);
  return (
    <div className="flex flex-col flex-wrap max-w-md py-6 mb-6 ml-auto mr-auto md:mb-12 lg:max-w-full lg:flex-row">
      <h1 className="mb-2 text-3xl font-bold text-gray-900 lg:w-2/5 title-font sm:mb-0">
        Andrés Prieto Franklin
        <div className="relative w-1/2 h-1 mb-4 rounded bg-radical-red-500">
          <div className="relative w-2/3 h-1 mb-4 rounded bg-bright-blue-500" />
        </div>
      </h1>

      <p className="pl-0 text-base leading-relaxed lg:w-3/5 lg:pl-10">
        <strong>Andrés Prieto Franklin</strong> es un creador de contenido colombiano, mejor
        conocido en redes sociales como 
        <a
          href="https://www.google.com/search?q=andres+en+ingles&rlz=1C5CHF
A_enUS964US966&sxsrf=AOaemvKJVOupi5Zg6eKXLHVpVputf12
RrA:1635018597114&source=lnms&tbm=nws&sa=X&sqi=2&ved=2
ahUKEwiT3rn1puHzAhV3pZUCHeYaBaMQ_AUoAnoECAIQBA&bi
w=1440&bih=629&dpr=2"
          className="underline text-radical-red-500 hover:no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>Andrés en Inglés</strong>
        </a>
        . Lanzó su canal de 
        <strong>YouTube</strong> el 2016 para enseñar inglés de manera divertida y fácil. Hoy en
        día, cuenta con más de tres millones de seguidores en {' '}
        <a
          href="https://www.tiktok.com/@andreseningles"
          className="underline text-radical-red-500 hover:no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tiktok
        </a>
        ,{' '}
        <a
          href="https://youtube.com/andreseningles"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-radical-red-500 hover:no-underline"
        >
          Youtube
        </a>
        ,{' '}
        <a
          href="https://www.facebook.com/andreseningles"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-radical-red-500 hover:no-underline"
        >
          Facebook
        </a>
         e{' '}
        <a
          href="https://www.instagram.com/andreseningles/"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-radical-red-500 hover:no-underline"
        >
          Instagram
        </a>
         como <strong>@andreseningles</strong>.
      </p>
    </div>
  );
}
