/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import claudinaryImage from '../utils/claudinaryImage';

import 'react-modal-video/scss/modal-video.scss';

export default function HomepageVideo({
  id = '747836838',
  title = 'Andres en Ingles Video',
  src = claudinaryImage('/v1634160534/maxresdefault.jpg'),
  className,
  iconWidth = 80,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className={className}>
      <div className="relative cursor-pointer" onClick={() => setIsOpen(true)}>
        <div className="frame frame916">
          <img src={src} alt={title} />
        </div>
        <img
          style={{
            position: 'absolute',
            width: iconWidth,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          src="icons/icon-play.svg"
          alt="play"
        />
      </div>

      <ModalVideo isOpen={isOpen} videoId={id} onClose={() => setIsOpen(false)} channel="vimeo" />
    </section>
  );
}
