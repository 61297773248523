import { graphql, useStaticQuery } from 'gatsby';

const useChannelsData = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      allYoutubeChannel {
        nodes {
          data {
            items {
              statistics {
                subscriberCount
                viewCount
              }
            }
          }
        }
      }
      # allTiktok {
      #   nodes {
      #     data {
      #       stats {
      #         followerCount
      #         followingCount
      #         heart
      #       }
      #       user {
      #         signature
      #         bioLink {
      #           link
      #         }
      #       }
      #     }
      #   }
      # }
      allYoutubeVideo {
        edges {
          node {
            videoId
            title
            thumbnail {
              url
            }
            localThumbnail {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      allYoutubePlaylist {
        nodes {
          data {
            items {
              snippet {
                resourceId {
                  videoId
                }
                thumbnails {
                  default {
                    url
                    height
                    width
                  }
                  standard {
                    url
                    height
                    width
                  }
                }
                publishedAt
                title
              }
            }
          }
        }
      }
    }
  `);

  const { allYoutubeChannel, allYoutubePlaylist, allYoutubeVideo } = data;

  return {
    // tiktok: data.allTiktok.nodes[0].data,
    youtube: {
      channel: allYoutubeChannel.nodes[0].data.items[0].statistics,
      latestVideo: allYoutubeVideo.edges[0].node,
      playlist: allYoutubePlaylist.nodes[0].data.items,
    },
  };
};

export default useChannelsData;
