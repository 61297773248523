import React from 'react';
import { FaTwitter, FaInstagram, FaFacebookF, FaYoutube } from 'react-icons/fa';
import NewsletterSubscribeForm from './NewsletterSubscribeForm';
import { FooterLinkIcon } from './SocialIconsBar';
import AdrianLogo from './svg/AdrianLogo';
import TikTokLogoSVG from './svg/TikTokLogoSVG';
import TikTokTextSVG from './svg/TikTokTextSVG';

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className="text-gray-700 body-font">
      <div className="border-t border-gray-200">
        <div className="flex flex-col flex-wrap items-center max-w-screen-xl py-8 mx-auto space-y-8 lg:flex-row">
          <div className="flex flex-col-reverse flex-wrap justify-center m-auto lg:flex-row lg:flex-no-wrap lg:justify-start">
            <NewsletterSubscribeForm className="mt-4 lg:mt-0" />
            <p className="text-sm text-center text-gray-500 lg:mb-0 lg:ml-6 md:mt-0 sm:text-left">
              <strong className="text-lg text-gray-900">Suscríbete a mi newsletter:</strong>
              <br />
              <span>Recibe mis tips y notificaciones directamente a tu email.</span>
            </p>
          </div>

          <nav className="flex m-auto space-x-6 lg:ml-auto">
            <FooterLinkIcon
              url="https://www.instagram.com/andreseningles/"
              component={FaInstagram}
              size="1em"
            />
            <FooterLinkIcon
              url="https://www.facebook.com/andreseningles"
              component={FaFacebookF}
              size="1em"
            />
            <FooterLinkIcon
              url="https://twitter.com/andreseningles_"
              component={FaTwitter}
              size="1em"
            />
            <FooterLinkIcon
              url="https://www.youtube.com/user/soyimaginario"
              component={FaYoutube}
              size="1.25em"
              className="text-pure-red-500"
            />
            <div className="flex space-x-1">
              <FooterLinkIcon
                url="https://www.tiktok.com/@andreseningles"
                component={TikTokLogoSVG}
                className="h-2"
              />
              <FooterLinkIcon
                url="https://www.tiktok.com/@andreseningles"
                component={TikTokTextSVG}
                className="h-2"
              />
            </div>
          </nav>
        </div>
      </div>

      <div className="flex w-full">
        <div className="w-2/3 h-2 bg-radical-red-500" />
        <div className="w-1/3 h-2 bg-bright-blue-500" />
      </div>
      <div className="p-4 space-y-6 bg-black md:p-0 md:space-y-0">
        <div className="container flex flex-col flex-wrap px-5 py-4 mx-auto sm:flex-row">
          <p className="m-auto text-sm text-center text-gray-200 md:m-0 lg:text-left sm:w-full md:flex-1">
            © {year} Andrés en Inglés — Designed and developed by my bro Adrian Prieto.
            <a
              href="https://twitter.com/aprietof"
              className="ml-1 text-bright-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              @aprietof <AdrianLogo className="inline-block w-5 h-5 ml-1" />
            </a>
          </p>
          <span className="w-full m-auto mt-2 text-sm text-center text-gray-300 md:m-0 lg:ml-auto sm:mt-0 lg:w-auto lg:mt-0">
            Remember to always stay happy!
          </span>
        </div>
      </div>
    </footer>
  );
}
