import React from 'react';
import claudinaryImage from '../utils/claudinaryImage';

export default function VogueCard({ className }) {
  return (
    <div
      className={`w-full m-auto overflow-hidden border border-gray-300 border-solid rounded-sm ${className}`}
    >
      <a
        href="https://www.vogue.co.uk/news/article/edward-enninful-vogue-challenge-covers"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={claudinaryImage('/v1603252077/vougue.jpg')}
          className="object-cover object-top w-full lg:h-120"
          alt="Edward Enninful’s Top 10: The Best #VogueChallenge Reader Covers"
        />
        <p className="px-4 py-3 font-bold text-gray-900">
          <span>Edward Enninful’s Top 10: The Best #VogueChallenge Reader Covers</span>
          <br />
          <small className="font-normal text-gray-600 font-proxima">June 12, 2020</small>
        </p>
      </a>
    </div>
  );
}
