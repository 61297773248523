import React from 'react';
import { FaTwitter, FaInstagram, FaFacebookF, FaYoutube } from 'react-icons/fa';
import { FooterLinkIcon } from './SocialIconsBar';
import TikTokLogoSVG from './svg/TikTokLogoSVG';
import useWindowSize from '../hooks/use-window-size';

export default function Header() {
  const { width } = useWindowSize();
  const isDesktop = width > 480;
  return (
    <div
      className="fixed top-0 z-50 w-full shadow-sm"
      style={{
        backdropFilter: isDesktop ? 'blur(5px)' : null,
        backgroundColor: isDesktop ? 'rgba(255,255,255, 0.75)' : 'white',
      }}
    >
      <header className="flex items-center h-20 max-w-screen-xl px-4 m-auto">
        <div className="hidden pt-2 font-bold sm:block">
          <span className="text-2xl tracking-normal uppercase">Andres</span>{' '}
          <span className="p-2 ml-1 text-white align-text-bottom bg-gray-900 rounded tiktok">
            en Inglés
          </span>
        </div>
        <nav className="flex ml-auto space-x-6">
          <FooterLinkIcon url="https://www.tiktok.com/@andreseningles" component={TikTokLogoSVG} />
          <FooterLinkIcon
            url="https://www.youtube.com/user/soyimaginario"
            component={FaYoutube}
            size="1.15em"
            className="text-pure-red-500"
          />
          <FooterLinkIcon
            url="https://www.instagram.com/andreseningles/"
            component={FaInstagram}
            size="1.15em"
          />
          <FooterLinkIcon
            url="https://www.facebook.com/andreseningles"
            component={FaFacebookF}
            size="1.15em"
          />
          <FooterLinkIcon
            url="https://twitter.com/andreseningles_"
            component={FaTwitter}
            size="1.15em"
          />
        </nav>
        <a className="ml-auto sm:ml-8 btn-red" href="mailto:andres@desbloqueatuingles.com">
          Contact
        </a>
      </header>
    </div>
  );
}
