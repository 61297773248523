/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';

import claudinaryImage from '../utils/claudinaryImage';

import LatestVideo from './LatestVideo';
import YoutubeNextCard from './YoutubeNextCard';
import HomepageVideo from './HomepageVideo';
import UnivisionLogoSVG from './svg/UnivisionLogoSVG';
import FacebookVideo from './FacebookVideo';
import HomepageHeroText from './HomepageHeroText';
import VogueCard from './VogueCard';
import OtherPublications from './OtherPublications';
import YoutubeBlogLogoSVG from './svg/YoutubeBlogLogoSVG';
import ButtonLink from './ButtonLink';
import ImageLink from './ImageLink';

export default function HeroBadges({ data }) {
  const { youtube } = data;
  const youtubeFollowers = `${youtube.channel.subscriberCount / 1000}K`;
  // const tiktokFollowers = `${tiktok.stats.followerCount / 1000000}M`;

  return (
    <section className="max-w-screen-xl m-auto mt-16 text-gray-700 sm:mt-14 body-font">
      <div className="px-4 py-4 mx-auto sm:px-5 sm:py-12">
        <div className="flex flex-col">
          {/* Hero text  */}
          <HomepageHeroText youtubeFollowers={youtubeFollowers} />

          {/* Columns  */}
          <div className="flex flex-wrap px-4 -mx-4 -mt-4 -mb-10 sm:px-0 sm:-m-4">
            {/* First Column */}
            <div className="max-w-md pl-0 mb-6 ml-auto mr-auto sm:p-4 lg:w-1/3 sm:mb-0">
              {/* Initial Image (Andres The Thinker) */}
              <img
                src={claudinaryImage('/v1603305103/finalforweb-4_1.jpg')}
                className="block object-cover w-full h-64 mb-6 rounded object-middle"
                alt="Edward Enninful’s Top 10: The Best #VogueChallenge Reader Covers"
              />

              <h2 className="mb-4 text-lg font-bold text-gray-900 title-font">
                Accede a mis tips y contenido GRATIS en:
              </h2>

              {/* Button Links  */}
              <div className="mb-6 space-y-3">
                <ButtonLink text="TikTok" href="https://www.tiktok.com/@andreseningles" />
                <ButtonLink
                  text="Youtube"
                  href="https://youtube.com/andreseningles
"
                />
                <ButtonLink text="Instagram" href="https://www.instagram.com/andreseningles" />
                <ButtonLink text="Facebook" href="https://www.facebook.com/andreseningles" />
                <ButtonLink text="Twitter" href="https://www.twitter.com/andreseningles_" />
                <ButtonLink
                  text="Grupo GRATIS de Telegram"
                  href="https://t.me/desbloqueatuingles"
                />
              </div>

              <h2 className="mb-4 text-lg font-bold text-gray-900 title-font">
                Descubre Mi Contenido PREMIUM
              </h2>

              {/* Button Links  */}
              <div className="mb-6 space-y-3">
                {/* Descarga APP Img Link */}
                <a
                  href="http://onelink.to/6fnsa4"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Descarga mi App GRATIS"
                >
                  <img
                    src={claudinaryImage('/v1635104569/ad_website_app_2.jpg')}
                    className="block object-cover w-full rounded object-middle"
                    alt="Descarga mi App GRATIS"
                  />
                </a>
                <ButtonLink
                  text="Descarga mi App GRATIS"
                  href="http://onelink.to/6fnsa4"
                  variant="green"
                />
                <br />
                <h2 className="mb-4 text-lg font-bold text-gray-900 title-font">
                  Desbloquea TU VOZ EN INGLÉS
                </h2>
                {/* Intro Video */}
                <HomepageVideo className="overflow-hidden rounded-md" />
                <ButtonLink
                  text="INSCRIBETE A MI CURSO ONLINE"
                  href="https://pay.hotmart.com/D48943707J?off=yheoqv5r&checkoutMode=10&bid=1686602958392"
                  variant="green"
                />
              </div>

              <h2 className="mb-4 text-lg font-bold text-gray-900 title-font">Mencionado en:</h2>

              <img
                src={claudinaryImage('/e_grayscale/v1635104568/PRENSA_BANNERS_color.png')}
                className="block object-cover w-full rounded object-middle"
                alt="Mencionado en:"
              />

              {/* Forbes Image */}
              <a
                href="https://www.forbes.com.ec/lifestyle/ensena-ingles-tik-tok-seis-meses-genero-mas-us-100000-n8683"
                target="_blank"
                rel="noopener noreferrer"
                title="Forbes - Andres en ingles el influencer colombiano que ensena el idioma en tiktok y genero U$100.000"
              >
                <img
                  src={claudinaryImage('/v1634160801/FORBES_ECUADOR_COVER.png')}
                  className="block object-cover w-full mb-6 rounded object-middle"
                  alt="Forbes - Andres en ingles el influencer colombiano que ensena el idioma en tiktok y genero U$100.000"
                />
              </a>

              {/* Article Image Links  */}
              <div className="space-y-4">
                {/* RCN  */}
                <ImageLink
                  href="https://www.canalrcn.com/super-like/tendencias/andres-en-ingles-es-tendencia-al-ganar-millones-en-30-minutos-148757"
                  alt="TikToker colombiano es tendencia por facturar millones en cuestión de minutos"
                  src="/v1634587050/Articulos/made-by-andres/RCN.png"
                />
                {/* Forbes  */}
                <ImageLink
                  href="https://forbes.co/2021/09/24/emprendedores/andres-en-ingles-el-colombiano-que-factura-700-000-por-minuto-en-tik-tok/"
                  alt="Forbes - Andrés en Inglés, el colombiano que factura $700.000 por minuto"
                  src="/v1634587050/Articulos/made-by-andres/FORBES_COLOMBIA.png"
                />
                {/* El Espectador  */}
                <ImageLink
                  href="https://www.elespectador.com/economia/emprendimiento-y-liderazgo/andres-en-ingles-un-colombiano-que-ensena-ingles-a-los-latinos-en-estados-unidos/"
                  alt="Un colombiano que enseña inglés a los latinos en Estados Unidos"
                  src="/v1634587049/Articulos/made-by-andres/EL_ESPECTADOR.png"
                />
              </div>
            </div>

            {/* Second Column */}
            <div className="flex flex-col w-full pl-0 overflow-hidden sm:p-4 lg:w-1/3 sm:mb-0">
              <div className="max-w-md m-auto space-y-6 lg:m-0">
                {/* Univision Facebook Embed  */}
                <div className="space-y-4">
                  <h2 className="text-xl font-medium text-gray-900 title-font">
                    <UnivisionLogoSVG />
                  </h2>
                  <FacebookVideo className="rounded-lg" />
                </div>

                {/* Article Image Links  */}
                <div className="space-y-4">
                  {/* Semana  */}
                  <ImageLink
                    href="https://www.semana.com/gente/articulo/el-influenciador-andres-en-ingles-fue-seleccionado-por-tiktok-para-probar-su-nueva-plataforma-de-ventas/202100/"
                    alt="El influenciador Andrés en Inglés fue seleccionado por TikTok para probar su nueva plataforma de ventas"
                    src="/v1637000187/Articulos/made-by-andres/SEMANA.png"
                  />
                  {/* El Espectador */}
                  <ImageLink
                    href="https://www.elespectador.com/entretenimiento/gente/andres-prieto-sin-barreras-para-crear-en-ingles/"
                    alt="Andrés Prieto, sin barreras para crear en inglés"
                    src="/v1637000187/Articulos/made-by-andres/ELESPECTADOR2.png"
                  />
                  {/* W Radio  */}
                  <ImageLink
                    href="https://www.wradio.com.co/noticias/tecnologia/andres-en-ingles-el-creador-de-contenido-que-vende-700000-por-minuto-en-tiktok/20211002/nota/4168700.aspx"
                    alt="Andrés en Inglés, el creador de contenido que vende $700.000 por minuto en TikTok"
                    src="/v1634587050/Articulos/made-by-andres/WRADIO.png"
                  />
                  {/* Caracol Radio */}
                  <ImageLink
                    href="https://caracol.com.co/programa/2021/10/02/a_vivir_que_son_dos_dias/1633202462_084727.html"
                    alt="Andrés en Inglés, el colombiano que enseña el idioma en Tik ..."
                    src="/v1634587049/Articulos/made-by-andres/CARACOL_RADIO.png"
                  />
                  {/* Forbes Argentina */}
                  <ImageLink
                    href="https://www.forbesargentina.com/tags/andres-ingles-t9548"
                    alt="Andres en ingles ensena el idioma en Tik Tok y en seis meses generó más de US$ 100.000."
                    src="/v1634588515/Articulos/made-by-andres/FORBES_ARGENTINA.png"
                  />
                  {/* Kinkeye  */}
                  <ImageLink
                    href="https://www.kienyke.com/marketing/andres-en-ingles-tik-tok-business"
                    alt="Andrés en Inglés: El colombiano que busca brillar en TikTok Business"
                    src="/v1634587050/Articulos/made-by-andres/KIENYKE.png"
                  />
                  {/* El confidencial  */}
                  <ImageLink
                    href="https://www.elconfidencial.com/alma-corazon-vida/2021-09-29/como-ganar-dinero-tiktok-videos_3298165/"
                    alt="Cómo ganar 150 euros por minuto subiendo vídeos a TikTok"
                    src="/v1634587050/Articulos/made-by-andres/EL_CONFIDENCIAL.png"
                  />
                  {/* Tu Barco  */}
                  <ImageLink
                    href="https://www.wradio.com.co/noticias/tecnologia/andres-en-ingles-el-creador-de-contenido-que-vende-700000-por-minuto-en-tiktok/20211002/nota/4168700.aspx"
                    alt="Colombiano es la estrella en Tik Tok: “Andrés en inglés”, enseña el idioma a latinos"
                    src="/v1634587050/Articulos/made-by-andres/TUBARCO.png"
                  />
                  {/* No somos nonos  */}
                  <ImageLink
                    href="https://nosomosnonos.com/2021/09/15/andres-en-ingles-tiktoker-ventas/"
                    alt="Andrés En Inglés - El Primer Tiktoker En Vender A Través De ..."
                    src="/v1634587050/Articulos/made-by-andres/nosomosnonos.png"
                  />
                  {/* Social Geek  */}
                  <ImageLink
                    href="https://socialgeek.co/redes-sociales/andres-en-ingles-el-primer-colombiano-invitado-por-tiktok-para-usar-su-nueva-plataforma-de-ventas/"
                    alt="Andrés En Inglés - El Primer Tiktoker En Vender A Través De ..."
                    src="/v1634587050/Articulos/made-by-andres/SOCIAL_GEEK.png"
                  />
                  {/* El confidencial Colombia  */}
                  <ImageLink
                    href="https://confidencialcolombia.com/tecnologia/andres-prieto-franklin-primer-colombiano-invitado-por-tiktok-para-usar-su-nueva-plataforma-de-ventas/2021/09/16/"
                    alt="Andres Prieto Franklin primer colombiano invitado por TikTok"
                    src="/v1634587050/Articulos/made-by-andres/CONFIDENCIAL_COLOMBIA.png"
                  />
                  {/* Portafolio */}
                  <ImageLink
                    href="https://www.portafolio.co/tendencias/como-ganar-dinero-a-traves-de-redes-sociales-557026"
                    alt="Como ganar dinero atravez de redes sociales"
                    src="/v1634587050/Articulos/made-by-andres/PORTAFOLIO.png"
                  />
                  {/* MasterK */}
                  <ImageLink
                    href="https://www.mastekhw.com/noticias/andres-en-ingles-genera-ingresos-a-traves-de-tiktok/"
                    alt="Andres en Ingles genera ingresos atravez de TikTok"
                    src="/v1634590203/Articulos/made-by-andres/MASTERK.png"
                  />
                  {/* MasterK */}
                  <ImageLink
                    href="https://www.cnet.com/es/google-amp/noticias/tiktok-como-descargar-todos-tus-videos-20-septiembre/?fbclid=IwAR0ZvUi9ODiMBEW2gFuLm8IBwIQcgLurnT-WrMl6k74Iz4qTQ_iHkAvw6lE"
                    alt="Como descargar tus videos de tiktok antes de que se cierre el app"
                    src="/v1635104569/CNET_ESPANOL.png"
                  />
                </div>

                <VogueCard />
                <br />
              </div>
            </div>

            {/* Third Column */}
            <div className="flex flex-col max-w-md pl-0 mb-6 ml-auto mr-auto sm:p-4 lg:w-1/3 sm:mb-0">
              <div className="mb-6">
                {/* Teaching Card */}
                <YoutubeNextCard />

                {/* Youtube Blog */}
                <div className="mb-6">
                  <a
                    className="space-y-2"
                    href="https://blog.youtube/news-and-events/meet-creators-heading-to-three-special"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h2 className="mb-3 text-xl font-medium text-gray-900 title-font">
                      <YoutubeBlogLogoSVG className="w-40" />
                    </h2>
                    <div className="flex items-center overflow-hidden border border-gray-300 border-solid rounded-sm">
                      <img
                        src={claudinaryImage('/v1603284131/ytblog.png')}
                        className="object-cover object-center w-24 h-24 "
                        alt="Meet the creators heading to three special editions of NextUp: #YouTubeLatino"
                      />

                      <p className="px-4 py-3 font-bold text-gray-900">
                        <span>Meet the creators heading to three special editions of NextUp</span>
                        <br />
                        <small className="font-normal text-gray-600 font-proxima">
                          September 05, 2018
                        </small>
                      </p>
                    </div>
                  </a>
                </div>

                {/* STeaching Image */}
                <img
                  src={claudinaryImage('/v1603285549/IMG_1136.jpg')}
                  className="block object-cover w-full h-64 mt-3 mb-6 rounded object-middle"
                  alt="Edward Enninful’s Top 10: The Best #VogueChallenge Reader Covers"
                />

                {/* Tweet Image Link */}
                <div className="mb-3">
                  <a
                    href="https://twitter.com/YouTubeMexico/status/1190398290636005376"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Youtube mexico tweet"
                  >
                    <img
                      src={claudinaryImage('/v1603375036/tweet-share.png')}
                      alt="Youtube mexico tweet"
                      className="w-full"
                    />
                  </a>
                </div>

                {/* Latest Video */}
                <LatestVideo youtube={youtube} className="hidden mb-6 sm:block" />

                <OtherPublications />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
