import React from 'react';

export default function YoutubeBlogLogoSVG({ className }) {
  return (
    <svg
      id="yt-logo-desktop"
      width="226"
      height="37"
      viewBox="0 0 226 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M120.912 21.0962V14.6796C120.912 8.6855 122.18 6.547 125.982 6.547C129.681 6.547 130.974 8.65965 130.974 14.6538V21.176C130.974 27.1173 129.6 29.2569 125.877 29.2569C122.18 29.2569 120.912 27.1173 120.912 21.0962ZM128.148 21.5985V14.0728C128.148 10.0318 127.647 8.65965 125.957 8.65965C124.267 8.65965 123.738 10.0598 123.738 14.0728V21.5716C123.738 25.6643 124.293 27.1431 125.931 27.1431C127.594 27.1443 128.148 25.6913 128.148 21.5985Z"
        fill="#212121"
      />
      <path
        d="M134.011 15.0237H132.452V12.8852H134.011V10.2174C134.011 7.0484 134.881 5.67517 137.259 5.67517C138.183 5.67517 139.028 5.93925 139.556 6.22918L139.081 7.97212C138.79 7.89234 138.289 7.7867 137.866 7.7867C136.811 7.7867 136.626 8.44635 136.626 10.0308V11.3254L136.52 12.8829H139.081V15.0214H136.52L136.599 16.9756V28.938H134.011V15.0237ZM141.167 15.0237H139.608V12.8852H141.167V10.2174C141.167 7.0484 142.038 5.67517 144.415 5.67517C145.339 5.67517 146.184 5.93925 146.712 6.22918L146.237 7.97212C145.92 7.89234 145.445 7.7867 145.022 7.7867C143.967 7.7867 143.782 8.44635 143.782 10.0308V11.3254L143.676 12.8829H146.237V15.0214H143.676L143.755 16.9756V28.938H141.167V15.0237ZM147.557 8.84415C147.557 7.41811 148.006 6.99558 149.089 6.99558C150.171 6.99558 150.62 7.44396 150.62 8.84415C150.62 10.296 150.171 10.6927 149.089 10.6927C148.006 10.6927 147.557 10.2972 147.557 8.84415ZM147.769 12.884H150.409V28.9391H147.769V12.884Z"
        fill="#212121"
      />
      <path
        d="M152.76 22.3381V19.3275C152.76 14.4426 154.028 12.621 157.197 12.621C158.596 12.621 159.601 12.9907 160.233 13.756C161.053 14.6797 161.343 16.344 161.157 18.5094L158.729 18.7207C158.729 15.5787 158.385 14.5752 157.17 14.5752C155.877 14.5752 155.48 15.7371 155.48 19.0376V22.5763C155.48 26.1408 155.797 27.25 157.117 27.25C158.438 27.25 158.807 26.1678 158.807 23.2101L161.236 23.3427C161.316 25.7723 160.946 27.3826 160.022 28.2546C159.336 28.8884 158.411 29.2053 157.064 29.2053C153.843 29.2031 152.76 27.4871 152.76 22.3381Z"
        fill="#212121"
      />
      <path
        d="M163.165 8.84418C163.165 7.41814 163.614 6.99561 164.696 6.99561C165.779 6.99561 166.228 7.44398 166.228 8.84418C166.228 10.2961 165.779 10.6928 164.696 10.6928C163.615 10.6928 163.165 10.2972 163.165 8.84418ZM163.377 12.8841H166.018V28.9391H163.377V12.8841Z"
        fill="#212121"
      />
      <path
        d="M168.077 25.4801C168.077 22.4426 170.005 20.4895 174.256 19.4062V17.9802C174.256 15.5776 173.965 14.5741 172.698 14.5741C171.325 14.5741 170.849 15.5517 170.876 18.033L168.447 17.8487C168.156 14.337 169.53 12.6199 172.803 12.6199C175.841 12.6199 176.897 14.3628 176.897 17.9543V28.939H174.863L174.573 27.1163C173.94 28.4637 172.883 29.2289 171.404 29.2289C169.449 29.2301 168.077 27.9097 168.077 25.4801ZM172.354 27.25C173.173 27.25 173.807 26.6949 174.255 25.9824V20.9918C171.852 21.7054 170.822 23.0775 170.822 25.0329C170.823 26.405 171.272 27.25 172.354 27.25Z"
        fill="#212121"
      />
      <path d="M179.511 5.96631H182.099V28.9403H179.511V5.96631Z" fill="#212121" />
      <path
        d="M188.146 6.7843H191.818C195.699 6.7843 197.231 8.26316 197.231 12.2772C197.231 14.8922 196.544 16.5025 194.722 17.2678C196.808 17.9016 197.627 19.4861 197.627 22.6023C197.627 26.8804 196.069 28.9402 192.03 28.9402H188.147V6.7843H188.146ZM190.787 8.87111V16.6351H191.685C193.876 16.6351 194.537 15.5529 194.537 12.5952C194.537 9.63751 193.903 8.87224 191.658 8.87224H190.787V8.87111ZM190.787 18.5095V26.8534H191.816C194.14 26.8534 194.853 25.6645 194.853 22.7079C194.853 19.54 194.086 18.5095 191.842 18.5095H190.787Z"
        fill="#212121"
      />
      <path d="M199.844 5.96631H202.432V28.9403H199.844V5.96631Z" fill="#212121" />
      <path
        d="M204.783 22.3909V19.5388C204.783 14.5741 206.077 12.6199 209.326 12.6199C212.6 12.6199 213.815 14.5999 213.815 19.5388V22.4168C213.815 27.3017 212.6 29.2031 209.299 29.2031C205.945 29.2031 204.783 27.2759 204.783 22.3909ZM211.067 23.2618V18.5612C211.067 15.8147 210.592 14.5999 209.299 14.5999C208.005 14.5999 207.556 15.8147 207.556 18.5612V23.209C207.556 26.0611 208.004 27.2489 209.325 27.2489C210.619 27.25 211.067 26.0869 211.067 23.2618Z"
        fill="#212121"
      />
      <path
        d="M218.7 25.1104C218.225 25.4003 218.013 25.797 218.013 26.2196C218.013 28.5693 225.46 26.6162 225.46 31.8968C225.46 34.6162 223.453 36.2535 220.099 36.2535C217.089 36.2535 215.214 34.9062 215.214 32.6092C215.214 30.9989 216.165 29.9426 217.695 29.4144C216.666 29.0177 215.9 28.3839 215.874 27.1961C215.874 26.087 216.482 25.1891 217.643 24.6609C216.428 23.8417 215.794 22.2044 215.794 19.4062V18.6668C215.794 14.4685 217.14 12.6469 220.125 12.6469C221.393 12.6469 222.343 12.9638 223.03 13.6504C223.427 12.3828 224.219 11.4579 225.538 11.3793L225.882 13.9931C224.958 14.019 224.297 14.337 223.875 14.9438C224.272 15.8675 224.456 17.0823 224.456 18.641V19.3804C224.456 23.7113 223.004 25.2958 220.152 25.2958C219.625 25.2958 219.123 25.2419 218.7 25.1104ZM220.205 34.2207C222.054 34.2207 223.11 33.4015 223.135 32.1867C223.161 30.5235 221.103 30.2595 219.175 29.8099C218.039 30.2066 217.512 30.9989 217.512 32.0013C217.512 33.4026 218.462 34.2207 220.205 34.2207ZM221.816 19.6703V18.4297C221.816 15.6046 221.394 14.6269 220.126 14.6269C218.885 14.6269 218.436 15.5776 218.436 18.4297V19.6703C218.436 22.4966 218.937 23.4203 220.126 23.4203C221.341 23.4203 221.816 22.4966 221.816 19.6703Z"
        fill="#212121"
      />
      <path
        d="M36.1526 8.97797C35.7271 7.38822 34.4778 6.13892 32.8881 5.71336C30.0096 4.94128 18.4623 4.94128 18.4623 4.94128C18.4623 4.94128 6.91502 4.94128 4.03654 5.71336C2.44685 6.13892 1.19759 7.38822 0.77205 8.97797C2.89876e-07 11.8565 0 17.866 0 17.866C0 17.866 2.89876e-07 23.8754 0.77205 26.754C1.19759 28.3437 2.44685 29.593 4.03654 30.0186C6.91502 30.7907 18.4623 30.7907 18.4623 30.7907C18.4623 30.7907 30.0096 30.7907 32.8881 30.0186C34.4778 29.593 35.7271 28.3437 36.1526 26.754C36.9247 23.8754 36.9247 17.866 36.9247 17.866C36.9247 17.866 36.9216 11.8565 36.1526 8.97797ZM14.7662 23.4043V12.3247L24.3591 17.8629L14.7662 23.4043Z"
        fill="#FF0000"
      />
      <path
        d="M53.5918 28.4614C52.8558 27.9703 52.3389 27.2084 52.0287 26.1611C51.7186 25.1272 51.5635 23.745 51.5635 22.0133V19.6613C51.5635 17.9161 51.7444 16.5204 52.0939 15.4607C52.4434 14.401 52.9985 13.638 53.7481 13.1469C54.4976 12.6693 55.4798 12.4232 56.6945 12.4232C57.8958 12.4232 58.8521 12.6693 59.5758 13.1604C60.2995 13.6515 60.8299 14.4269 61.1648 15.4742C61.5008 16.5204 61.6694 17.9172 61.6694 19.6613V22.0133C61.6694 23.745 61.5008 25.1272 61.1783 26.1746C60.8558 27.2208 60.3254 27.9838 59.5893 28.4749C58.8521 28.9525 57.87 29.1986 56.617 29.1986C55.3236 29.1975 54.3155 28.9525 53.5918 28.4614ZM57.6756 25.915C57.8823 25.3846 57.9722 24.5193 57.9722 23.3304V18.2903C57.9722 17.1272 57.8689 16.2743 57.6756 15.745C57.4688 15.2023 57.1204 14.9438 56.6024 14.9438C56.1113 14.9438 55.7629 15.2157 55.5562 15.745C55.3494 16.2878 55.2584 17.1272 55.2584 18.2903V23.3304C55.2584 24.5317 55.3483 25.3846 55.5427 25.915C55.7371 26.4454 56.0855 26.7028 56.6024 26.7028C57.1204 26.7174 57.4823 26.4454 57.6756 25.915Z"
        fill="#212121"
      />
      <path
        d="M109.666 22.0514V22.8662C109.666 23.9 109.692 24.6889 109.757 25.2047C109.822 25.7216 109.952 26.097 110.145 26.3419C110.339 26.5745 110.636 26.7038 111.037 26.7038C111.58 26.7038 111.954 26.497 112.162 26.0711C112.369 25.6452 112.472 24.9474 112.498 23.9641L115.639 24.145C115.651 24.2866 115.665 24.481 115.665 24.726C115.665 26.2251 115.251 27.3365 114.438 28.0725C113.624 28.8086 112.461 29.1839 110.961 29.1839C109.165 29.1839 107.911 28.6153 107.187 27.4904C106.463 26.3655 106.102 24.6215 106.102 22.256V19.4264C106.102 16.9968 106.477 15.2269 107.226 14.102C107.976 12.9906 109.255 12.422 111.064 12.422C112.318 12.422 113.273 12.6546 113.946 13.1075C114.618 13.5592 115.084 14.2829 115.355 15.2392C115.626 16.209 115.768 17.5396 115.768 19.2578V22.0368H109.668V22.0514H109.666ZM110.131 15.2415C109.95 15.4741 109.821 15.8483 109.757 16.3652C109.692 16.8822 109.666 17.671 109.666 18.7296V19.8927H112.328V18.7296C112.328 17.6958 112.289 16.9069 112.225 16.3652C112.161 15.8225 112.032 15.4483 111.851 15.228C111.67 15.0089 111.385 14.892 110.998 14.892C110.609 14.892 110.312 15.0078 110.131 15.2415Z"
        fill="#212121"
      />
      <path
        d="M44.7142 21.755L40.5664 6.77761H44.1849L45.6323 13.5617C46.0065 15.2282 46.2784 16.6633 46.4459 17.8387H46.5493C46.6785 16.9993 46.9504 15.5766 47.364 13.5864L48.8631 6.77649H52.4816L48.2821 21.755V28.9403H44.6895V21.755H44.7142Z"
        fill="#212121"
      />
      <path
        d="M73.4161 12.7469V28.9268H70.5595L70.237 26.9489H70.1594C69.3841 28.448 68.221 29.1976 66.6702 29.1976C65.5981 29.1976 64.8093 28.8492 64.2923 28.1379C63.7754 27.44 63.5293 26.3286 63.5293 24.8419V12.7469H67.1736V24.6239C67.1736 25.3476 67.2512 25.8645 67.4063 26.1747C67.5613 26.4848 67.8333 26.6399 68.194 26.6399C68.5165 26.6399 68.8143 26.55 69.0986 26.3556C69.3829 26.1612 69.6032 25.9162 69.7448 25.6184V12.7469H73.4161Z"
        fill="#212121"
      />
      <path
        d="M92.1163 12.7469V28.9268H89.2597L88.9372 26.9489H88.8596C88.0842 28.448 86.9212 29.1976 85.3704 29.1976C84.2972 29.1976 83.5094 28.8492 82.9925 28.1379C82.4756 27.44 82.2295 26.3286 82.2295 24.8419V12.7469H85.8738V24.6239C85.8738 25.3476 85.9514 25.8645 86.1064 26.1747C86.2615 26.4848 86.5335 26.6399 86.8942 26.6399C87.2178 26.6399 87.5145 26.55 87.7988 26.3556C88.0831 26.1612 88.3034 25.9162 88.445 25.6184V12.7469H92.1163Z"
        fill="#212121"
      />
      <path
        d="M83.3284 9.70949H79.7099V28.9257H76.1431V9.70949H72.5247V6.77649H83.3284V9.70949Z"
        fill="#212121"
      />
      <path
        d="M104.173 15.3314C103.954 14.3099 103.592 13.5739 103.101 13.1086C102.61 12.6558 101.924 12.4243 101.071 12.4243C100.399 12.4243 99.779 12.6175 99.198 12.9929C98.617 13.3682 98.1641 13.8717 97.854 14.4796H97.8147V5.95032H94.2996V28.9277H97.3101L97.6854 27.3893H97.763C98.0473 27.9321 98.4608 28.3714 99.0294 28.6816C99.5981 29.0052 100.218 29.1603 100.903 29.1603C102.13 29.1603 103.036 28.5917 103.617 27.4545C104.198 26.3172 104.483 24.5462 104.483 22.1301V19.5713C104.496 17.7744 104.393 16.3518 104.173 15.3314ZM100.826 21.9346C100.826 23.11 100.775 24.0416 100.684 24.7125C100.593 25.3834 100.425 25.8621 100.193 26.134C99.9599 26.4184 99.6498 26.5611 99.2621 26.5611C98.9643 26.5611 98.6811 26.497 98.435 26.3543C98.1765 26.2116 97.9697 26.0048 97.8147 25.7216V16.5203C97.9439 16.081 98.1507 15.7191 98.4473 15.4348C98.7451 15.1505 99.0676 15.0089 99.4295 15.0089C99.8048 15.0089 100.089 15.1516 100.282 15.4483C100.489 15.7326 100.632 16.2237 100.709 16.908C100.787 17.5924 100.826 18.5746 100.826 19.8422V21.9346Z"
        fill="#212121"
      />
    </svg>
  );
}
