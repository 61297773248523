import React from 'react';
import { Helmet } from 'react-helmet';
import SEO from '../components/SEO';
import Header from '../components/Header';
import useChannelsData from '../hooks/use-channels-data';
import HeroBadges from '../components/HeroBadges';
import Footer from '../components/Footer';

export default function Index() {
  const data = useChannelsData();

  return (
    <div className="text-gray-900 font-sofia">
      <SEO />
      <Helmet>
        <script async src="https://www.tiktok.com/embed.js" />
      </Helmet>
      <Header />
      <HeroBadges data={data} />
      <Footer />
    </div>
  );
}
